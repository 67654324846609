function showHide(tag, id) {
	var obj = document.getElementsByTagName(tag);
	if (obj[id].style.display == "") {
		var t;
		if (tag == 'table') {
			t = "table";
		}
		else {
			t = "block";
		}
		obj[id].style.display = t;
	}
	else {
		obj[id].style.display = '';
	}
}

function showLogin() {
	var auth = document.getElementById('auth');
	if (auth) {
		if (auth.style.display == '') {
			auth.style.display = 'block';
			document.getElementById('txtUsername').focus();
		}
		else {
			auth.style.display = '';
		}
	}

	return false;
}

function sh(id, showClass) {
	var o = document.getElementById(id);
	if (o.className == 'hide') o.className = showClass;
	else o.className = 'hide';
}

function helpme(s) {
	tb_show('Hjï¿½lp', s + '?width=400&amp;height=400');
	//var popup = window.open(s, 'Hjï¿½lp', 'toolbar=no,location=no,directories=no,status=no,scrollbars=2,menubar=no,titlebar=no,width=400,height=400');
	//popup.focus();
	return false;
}

moya = window.moya || {};
moya.division = null;
moya.lang = null;

moya.admin = {};
moya.admin.changeDivision = function (el) {
	window.location = '/' + $(el).val();
};
moya.login = window.moya.login || {};
moya.login.delay = 400;
moya.login.last = 0;
moya.login.tHandle = null;
moya.login.going = false;
moya.login.click = function (e, fClick, fDblClick) {
	if (window.event) {
		e = window.event;
	}

	if (moya.login.going) return false; // Try to prevent double run

	var cur = new Date().getTime();
	clearTimeout(moya.login.tHandle);
	if (moya.login.last > 0 && (moya.login.last + moya.login.delay) > cur) {
		moya.login.going = true;
		fDblClick();
	}
	else {
		moya.login.tHandle = setTimeout(fClick, moya.login.delay);
	}
	moya.login.last = cur;
	if (e.preventDefault) {
		e.preventDefault();
	}
	else {
		e.returnValue = false;
	}
	return false;
};

moya.login.init = function (id, division) {
	var loginUrl = '/moya?return_url=' + encodeURIComponent(window.location.href);
	if (division) {
		loginUrl = '/' + division + loginUrl;
	}
	var el = document.getElementById(id);
	if (el) {
		el.useDefault = false;
		el.onclick = function (e) {
			if (el.useDefault) return true;
			return moya.login.click(
				e,
				function () {
					if (navigator.userAgent.indexOf('MSIE') !== -1 || navigator.appVersion.indexOf('Trident/') > 0) {
						window.location = el.href;
						return true;
					} else {
						el.useDefault = true;
						el.click();
						el.useDefault = false;
					}
				},
				function () {
					window.location = loginUrl;
				}
			);
		};
		// for IE dblclick event actually runs
		el.ondblclick = function () {
			window.location = loginUrl;
			return false;
		}
	}
};

moya.message = window.moya.message || {};
moya.message.duration = 500;
moya.message.divId = 'notice';
moya.message.add = function (msg, className) {
	if (typeof className == 'undefined') {
		className = 'notice';
	}
	$('#' + moya.message.divId).html("<div class=\"message\"><div class=\"" + className + "\">" + msg + "</div></div>");
};
moya.message.show = function () {
	var timeout = 200;
	$('#growl .message').each(function () {
		var el = this;
		setTimeout(function () {
			$(el).slideDown(moya.message.duration)
		}, timeout);
		timeout += 200;
	});
};
moya.message.hide = function () {
	$('#growl .message').slideUp(moya.message.duration);
};
moya.message.growl = function (message, type) {
	if (typeof type == 'undefined') {
		type = 'notice';
	}
	$('#growl').html("<div class=\"message\"><div class=\"" + type + "\">" + message + "</div></div>");
	moya.message.show();
	setTimeout(moya.message.hide, 10000);
}

moya.help = window.moya.help || {};
moya.help.show = function (title, message) {
	var t = null;

	if (typeof title != 'string') {
		t = title;
		title = $('span.title', $(t)).html();
		if (!message) {
			message = $('span.message', $(t)).html();
		}
	}
	$('#help-modal').remove();
	var html = $('<div id="help-modal">' + message + '</div>');
	$('body').append(html);
	$('#help-modal').dialog({
		modal: true,
		closeOnEscape: true,
		width: 512,
		title: title,
		buttons: {
			Ok: function () {
				$(this).dialog('close');
				$('#help-modal').remove();
			}
		}
	});
	return false;
};
moya.search = window.moya.search || {};
moya.sitemap = window.moya.sitemap || {};
moya.sitemap.gotoExpansion = function (menuElement) {
	$('li.goto > a', menuElement).each(function () {
		$(this).click(function (e) {
			$(this).parent().children('ul').toggle();
			e.preventDefault();
			return false;
		});
	});
};
moya.fancybox = window.moya.fancybox || {};
moya.fancybox.ecCounter = 0;
moya.fancybox.init = function () {
	$(".entryContent").each(function () {
		var exp = moya.fancybox.ecCounter++;
		$("a.fancybox", this).each(function () {
			$(this).attr("rel", "ec_" + exp);
		});
		$("a.slideshow", this).each(function () {
			$(this).attr("rel", "sc_" + exp);
		});
	});
};
moya.user = window.moya.user || {};
moya.user.addAttributePair = function () {
	var numberOfRows = $('#userAttributes tbody').children().length;
	var nextIndex = numberOfRows;
	var last = $('#userAttributes tr:last');
	last.after('<tr>' + last.html() + '<\/tr>');

	// Update new last
	var count = 1;
	var type;

	($('#userAttributes tr:last :input')).each(function (i) {
		type = (count == 1) ? 'key' : 'value';
		this.value = '';
		this.id = 'attributes' + '-' + type + '-' + nextIndex;
		this.name = 'attributes' + '[' + type + ']' + '[' + nextIndex + ']';
		count = 3 - count;
	});

	return false;
};

(function($, window) {
	var MoyaFormFile = function(element, options, language) {
		this.element = $(element);
		this.darkroom = null;
		this.fileElement = null;
		this.dropzoneElement = null;
		this.previewElement = null;
		this.errorsElement = null;

		this.setOptions(options);
		this.setLanguage(language);
		this.init();
	};

	$.extend(MoyaFormFile.prototype, {
		// Init everything
		init: function () {
			if (!this.settings.width || !this.settings.height) {
				throw 'Options for width and height have to be specified.';
			}
			this.initSettings();
			this.initPreview();
			this.initErrors();
			this.initClickable();
			this.initDroppable();

			return this;
		},

		// remove everything and reset the file element
		destroy: function() {
			// Reset the file element. We wrap it with a form element and reset that form.
			this.element.wrap('<form>').closest('form').get(0).reset();
			this.element.unwrap();

			// Reset classes on file element
			this.element.removeClass('has-preview').removeClass('has-dropzone');

			// Remove the zones
			this.dropzoneElement.empty().remove();
			this.previewElement.empty().remove();

			return this;
		},

		// Create settings based on file element
		initSettings: function () {
			this.setWidth(this.settings.width);
			this.setHeight(this.settings.height);
			this.setAspect(this.settings.width, this.settings.height);
		},

		setOptions: function(options) {
			this.settings = $.extend( {
				enableDarkroom: true,
				enableRotate: false,
				previewHeight: 'auto',
				previewWidth: 960,
				aspect: 1,
				multiple: false,
				language: 'is',
				onSaveImage: function() {}
			}, options);

			return this;
		},

		setLanguage: function(language) {
			this.language = $.extend( {
				is: {
					imageTooSmall: 'Þessi mynd er of lítil og mun ekki koma vel út.'
				},
				en: {
					imageTooSmall: 'The image you selected is too small and will pixelate.'
				}
			}, language);
		},

		setHeight: function(height) {
			this.settings.previewHeight = (height && height > 0) ? height : 'auto';
		},

		setWidth: function(width) {
			this.settings.previewWidth = (width && width <= 960) ? width : 960;
		},

		setAspect: function(width, height) {
			this.settings.aspect = (width > 0 && height > 0) ? width / height : 1;
		},

		_: function(langKey) {
			var lang = this.settings.language;
			if (this.language[lang] && this.language[lang][langKey]) {
				return this.language[lang][langKey];
			}

			return langKey;
		},

		// Bind click to readFiles
		initClickable: function () {
			this.fileElement = this.element.find('input[type="file"]');

			if (this.fileElement.length === 0) {
				this.element.append('<input type="file">');
				this.fileElement = this.element.find('input[type="file"]');
			}

			var self = this;
			var events = $._data(this.fileElement[0], 'events');

			if (!events || !events.change) {
				this.fileElement.on({
					change: function (e) {
						e.preventDefault();
						self.readFiles(self.fileElement.get(0).files);
					}
				});
			}
		},

		// Create the dropzone and events
		initDroppable: function () {
			var self = this;
			this.dropzoneElement = this.element.find('.file-dropzone');

			if (this.dropzoneElement.length === 0) {
				this.element.append('<div class="file-dropzone"><i class="fa fa-bullseye"></i></div>');
				this.dropzoneElement = this.element.find('.file-dropzone');
			}

			this.element.addClass('has-dropzone');

			var events = $._data(this.dropzoneElement[0], 'events');

			if (!events || !events.drop) {
				this.dropzoneElement.on({
					click: function (e) {
						e.preventDefault();
						self.fileElement.trigger('click');
					},
					drop: function (e) {
						$('body').removeClass('droppable');
						self.readFiles(e.originalEvent.dataTransfer.files);
					}
				});
			}

			events = $._data($(window)[0], 'events');
			if (!events || !events.drop) {
				$(window).on({
					dragover: function (e) {
						e.preventDefault();
						$('body').addClass('droppable');
					},
					dragleave: function (e) {
						e.preventDefault();
						$('body').removeClass('droppable');
					},
					drop: function (e) {
						e.preventDefault();
					}
				});
			}
		},

		// Create the preview element
		initPreview: function () {
			this.previewElement = this.element.find('.file-preview');

			if (this.previewElement.length === 0) {
				this.element.append('<div class="file-preview" />');
				this.previewElement = this.element.find('.file-preview');
			}
			
			this.element.addClass('has-preview');
		},

		// Create the errors element
		initErrors: function () {
			this.errorsElement = this.element.find('.file-errors');

			if (this.errorsElement.length === 0) {
				this.element.append('<div class="file-errors" />');
				this.errorsElement = this.element.find('.file-errors');
			}
		},

		addError: function(message, type) {
			if (!type) {
				type = 'warning';
			}

			this.errorsElement.append('<div class="message"><div class="'+type+'">'+message+'</div></div>');
		},

		// Read files added to file input
		readFiles: function (files) {
			if (files && files.length) {
				// Clear all errors
				this.errorsElement.empty();

				// Clear the preview
				this.previewElement.empty();

				for (var i = 0; i < files.length; i++) {
					var file = files[i];
					var imageType = /^image\//;

					// Check if we have an image
					if (!imageType.test(file.type)) {
						continue;
					}

					this.addPreview(file);
				}
			}
		},

		// Read a single file and add to preview
		addPreview: function (file) {
			var self = this,
				reader = new FileReader(),
				image = new Image();

			// Add an error if uploaded image is smaller than minimum dimensions
			image.onload = function (e) {
				if (image.width < self.settings.previewWidth || image.height < self.settings.previewHeight) {
					self.addError(self._('imageTooSmall'));
				}
			};

			reader.onload = function (e) {
				var $img = $('<img src="' + e.target.result + '" class="animated fadeIn">');
				self.previewElement.append($img);
				self.previewElement.append('<input type="hidden" name="images[]" value="' + e.target.result + '">');

				image.src = e.target.result;

				// Init darkroom
				if (self.settings.enableDarkroom) {
					self.darkroom = new Darkroom($img[0], {
						minWidth: 100,
						minHeight: 100,
						maxWidth: 960,
						backgroundColor: null,
						plugins: {
							save: false,
							rotate: self.settings.enableRotate,
							crop: {
								minWidth: self.settings.previewWidth,
								minHeight: self.settings.previewHeight,
								ratio: self.settings.aspect
							}
						},
						init: function () {
							this.addEventListener('image:save', function() {
								self.updateImage(self.darkroom.snapshotImage());
							});

							var cropPlugin = this.getPlugin('crop');
							cropPlugin.selectZone(0, 0, self.settings.previewWidth, self.settings.previewHeight);
						}
					});
				}
			};
			reader.readAsDataURL(file);
		},

		// Update the image hidden field with base64 data
		updateImage: function(data) {
			this.previewElement.find('input').val(data);
			this.settings.onSaveImage.call(this);
			var imagesEl = this.element.find('.images');
			if (this.settings.multiple === true && imagesEl.length > 0) {
				var input = this.previewElement.find('input');
				var imageElement = $('<div class="image" />');
				imageElement.append('<div class="actions"><a href="#" class="delete"><img src="//static.stefna.is/icons/16/Delete.png" alt="icon" class="icon"></a></div>');
				imageElement.append('<img src="' + input.val() + '">');
				imageElement.append(input);
				this.element.find('.images').append(imageElement);
				this.previewElement.empty();
			}
		}
	});

	$.fn['moyaFormFile'] = function (options) {
		if (this.length > 0) {
			var el = this[0];
			if (!$.data(el, 'plugin_moyaFormFile')) {
				$.data(el, 'plugin_moyaFormFile', new MoyaFormFile(el, options));
			}
			else {
				return $.data(el, 'plugin_moyaFormFile').destroy().setOptions(options).init();
			}
		}
		return this;
	};
})($, window);