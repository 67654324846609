moya = window.moya || {};

moya.sitemap = {
	itemId: 0,
	defaultParent: 0,
	subItems: [],
	lang : {},
	canEditRoot: true
};

moya.sitemap.init = function(itemId, defaultParent, subItems, lang, canEditRoot) {
	moya.sitemap.itemId = itemId;
	moya.sitemap.defaultParent = defaultParent;
	moya.sitemap.subItems = subItems;
	moya.sitemap.lang = lang;
	moya.sitemap.canEditRoot = canEditRoot;

	if (itemId > 0 && subItems.length > 0) {
		moya.sitemap.disableParents();
	}

	$('#linkType').change(function() {
		moya.sitemap.typeSelect();
	});

	$('#sitemap_item_form').submit(function() {
		var $nameEl = $('#name');
		var name = $nameEl.val();
		if (name.length < 1) {
			alert(lang.sitemapErrorName);
			$nameEl.focus();
			return false;
		}
		var type = moya.sitemap.typeGet();
		if (type.length < 1) {
			alert(lang.sitemapErrorLinkType);
			$('#linktype').focus();
			return false;
		}
		var v = moya.sitemap.getKey();
		if (v.length < 1) {
			var msg = '';
			if (type == 'module') {
				msg = lang.sitemapErrorLinkKeyModule;
			}
			else if (type == 'page') {
				msg = lang.sitemapErrorLinkKeyPage;
			}
			else if (type == 'gallery') {
				msg = lang.sitemapErrorLinkKeyGallery;
			}
			else if (type == 'content'){
				msg = lang.sitemapErrorLinkKeyContent;
			}
			else if (type == 'formbuilder'){
				msg = lang.sitemapErrorLinkKeyFormbuilder;
			}
			else {
				msg = lang.sitemapErrorLinkKeyLink;
			}
			alert(msg);
			$('#linkkey_' . type).focus();
			return false;
		}
		$('#linkKey').val(v);
	});

	$('.tabs').tabs();

	moya.sitemap.typeSelect();

	if (canEditRoot === false) {
		$('.p1').hide();
		$('.p2 a').first().trigger('click');
	}
};

moya.sitemap.typeSelect = function()
{
	$('#linkkey_module-label label,#linkkey_page-label label,#linkkey_gallery-label label,#linkkey_link-label label,#linkkey_content-label label,#linkkey_formbuilder-label label').each(function() {
		var $this = $(this);
		if (!$this.hasClass('hidden')) {
			$this.addClass('hidden');
		}
	});

	$('.linkkey').each(function() {
		var $this = $(this);
		if (!$this.hasClass('hidden')) {
			$this.addClass('hidden');
		}
	});

	var v = moya.sitemap.typeGet();
	var ename = 'linkkey_' + v;
	var e = $('#' + ename);
	e.removeClass('hidden');
	$('#'+ename+'-label label').removeClass('hidden');


	var $ke = $('#linkKey');
	var k = $ke.val();

	if (v == 'link') {
		e.val(k);
	}
	else {
		// Chrome create an empty option element if the key does not exist
		var sel = '#'+ename+' option[value=' + k + ']';
		if (k && $(sel).length > 0) {
			e.val(k);
		}
	}
};

moya.sitemap.getKey = function()
{
	var v = moya.sitemap.typeGet();
	var e = 'linkkey_' + v;
	return $('#' + e).val();
};

moya.sitemap.typeGet = function()
{
	return $('#linkType').val();
};

moya.sitemap.disableParents = function()
{
	$('#parent option').each(function(){
		var val = parseInt(this.value);
		if (moya.sitemap.subItems.indexOf(val) >= 0) {
			moya.sitemap.disableOption($(this));
		}
	});
};

moya.sitemap.disableOption = function(t)
{
	if ($.browser.msie) {
		t.css( { color: '#ccc' });
		t.parent().change(moya.sitemap.parentChangeIe);
	}
	else {
		t.attr("disabled", "disabled");
	}
};

moya.sitemap.parentChangeIe = function()
{
	var p = $('#parent');
	var v = parseInt(p.val());
	if (moya.sitemap.subItems.indexOf(v) >= 0) {
		p.val(moya.sitemap.defaultParent);
	}
};