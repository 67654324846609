moya = window.moya || {};

moya.adverts = {};

moya.adverts.sort = {
	init: function(url) {
		var $sortList = $('#sortList');
		$sortList.sortable({
			update: function(){
				var order = $(this).sortable('toArray').toString();
				$.ajax({
					type: "POST",
					url: url,
					data: {order:order, _skipResize : 1}
				});
			}
		});
		$sortList.disableSelection();
	}
};