$.fn.responsiveImg = function (options) {

	var $w = $(window);
	var settings = $.extend({
		sizes: {
			lg: 1200,
			md: 960,
			sm: 768,
			xs: 480
		},
		resize: true,
		threshold: 80
	}, options);

	var elements = $(this);

	function isRetina() {
		var mediaQuery = '(-webkit-min-device-pixel-ratio: 1.5), (min--moz-device-pixel-ratio: 1.5), (-o-min-device-pixel-ratio: 3/2), (min-resolution: 1.5dppx)';
		if (window.devicePixelRatio > 1) {
			return true;
		}
		return !!(window.matchMedia && window.matchMedia(mediaQuery).matches);
	}

	function getSrc(e) {
		var screen = $w.width();
		var sizes = settings.sizes;
		var src = e.attr('src');
		var retina = isRetina();
		// Lets save the original so we can come back to it later
		if (e.data('src') != undefined) {
			src = e.data('src');
		}

		// Set data attributes
		$.each(sizes, function (key, value) {
			if (screen < value && e.data(key) != undefined) {
				if (retina && e.data(key + '-at2x') !== undefined) {
					key += '-at2x';
				}
				src = e.data(key);
			}
		});

		return src;
	}


	// Detect if element is in view
	function getInView(e) {
		var wt = $w.scrollTop(),
		wb = wt + $w.height(),
		et = e.offset().top,
		eb = et + e.height();
		return eb >= wt - settings.threshold && et <= wb + settings.threshold;
	}

	// Load the correct image based on screen size if it is in view
	function breakpoints() {
		elements.each(function () {
			var e = $(this);
			var src = getSrc(e);

			// If we are setting the same image just stop now.
			if (e.attr('src') == src) {
				return true;
			}

			var inView = getInView(e);

			if (inView && src != undefined) {
				if (e.is('img')) {
					e.attr('src', src);
				} else {
					e.css('background-image', 'url(' + src + ')');
				}

				e.addClass('loaded animated fadeIn');
			}
		});
	}

	$w.on('scroll', breakpoints);

	if (settings.resize == true) {
		$w.on('resize', function () {
			breakpoints();
		});
	}

	breakpoints();
};