function EAS_load_fif(opts) {
    if (typeof opts !== 'object') {
        opts = {
            divId: arguments[0],
            fifSrc: arguments[1],
            easSrc: arguments[2],
            width: arguments[3],
            height: arguments[4],
            fixedSrc: arguments[5],
            adjustSize: arguments[6]
        }
    }
    var div = document.getElementById(opts.divId);

	if (div) {
		var fif = document.createElement('iframe');
		fif.src = opts.fifSrc;
		fif.style.width = opts.width + 'px';
		fif.style.height = opts.height + 'px';
		fif.style.margin = 0;
		fif.style.borderWidth = 0;
		fif.style.padding = 0;
		fif.scrolling = 'no';
		fif.frameBorder = 0;
		fif.allowTransparency = 'true';

		var easSrc = (opts.fixedSrc) ? opts.easSrc : opts.easSrc + ';fif=y';
		easSrc += EAS_getCxProfileCookieData();
		fif.EAS_src = easSrc;

		if (opts.adjustSize) {
			var FIF_ONLOAD_DELAY_MS = 200;
			var CHECK_CONTENT_SIZE_TIMES = 15;
			var fifOnloadFired = 0;
			var EAS_fif_onload = function EAS_fif_onload() {
				var doc = fif.contentDocument || fif.contentWindow.document;

				// Fix image link size: if the link contains an image, make their sizes equal
				var links = doc.getElementsByTagName('a');
				var linkChildren = null;
				var imgWidth;
				var imgHeight;
				var bodyWidth = doc.body.clientWidth;
				var bodyHeight = doc.body.clientHeight;
				var currentLink;
				var currentChild;
				for (var i = links.length - 1; i >= 0; i--) {
					currentLink = links[i];
					linkChildren = currentLink.getElementsByTagName('*');
					currentChild = linkChildren[0];
					if (linkChildren.length === 1 && currentChild.tagName.toLowerCase() === 'img') {
						currentChild.style.display = 'block';
						currentLink.style.display = 'inline-block';
						imgWidth = currentChild.clientWidth;
						imgHeight = currentChild.clientHeight;
						if (bodyWidth < imgWidth) {
							doc.body.style.minWidth = imgWidth + 'px';
						}
						if (bodyHeight < imgHeight) {
							doc.body.style.minHeight = imgHeight + 'px';
						}
					}
				};

				// Check new dimensions and update width/height
				if (bodyWidth && bodyHeight &&
					(bodyWidth != fif.clientWidth || bodyHeight != fif.clientHeight)
				) {
					fif.style.width = bodyWidth + 'px';
					fif.style.height = bodyHeight + 'px';
					div.style.width = fif.style.width;
					div.style.height = fif.style.height;
				}
				if (fifOnloadFired++ < CHECK_CONTENT_SIZE_TIMES) {
					setTimeout(EAS_fif_onload, FIF_ONLOAD_DELAY_MS);
				}
			}
			fif.onload = EAS_fif_onload;
		}
		div.appendChild(fif);
	}
}

function EAS_getCookie(check_name) {
   var a_all_cookies = document.cookie.split(';');
   var a_temp_cookie = '';
   var cookie_name = '';
   var cookie_value = '';
   var b_cookie_found = false;

   for (var i = 0; i < a_all_cookies.length; i++) {
      a_temp_cookie = a_all_cookies[i].split('=');

      cookie_name = a_temp_cookie[0].replace(/^\s+|\s+$/g, '');

      if (cookie_name == check_name) {
         b_cookie_found = true;
         if (a_temp_cookie.length > 1) {
            cookie_value = unescape(a_temp_cookie[1].replace(/^\s+|\s+$/g, ''));
         }
         return cookie_value;
      }

      a_temp_cookie = null;
      cookie_name = '';
   }

   if (!b_cookie_found) {
      return null;
   }
}

function EAS_getCxProfileCookieData() {
   var result = '';
   if (typeof EAS_getCookie != 'undefined') {
      var data = EAS_getCookie('cx_profile_data'),
         dataObj;
      if (data && data != 'undefined') {
         try {
            dataObj = JSON.parse(data);
         } catch (e) {
            if (window.console && console.error) {
              console.error('EAS_fif: cannot parse cx_profile_data');
              console.log(data);
            }

         }
         for (var category in dataObj) {
            result += '&EAST' + category + '=' + dataObj[category].join('|');
         }
      }
   }
   return result;
}
