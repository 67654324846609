moya = window.moya || {};

moya.boxman = {
	divisionId: 0,
	urls: {}
};

moya.boxman.init = function(divisionId, urls)
{
	moya.boxman.divisionId = divisionId;
	moya.boxman.urls = urls;

	$('#division').change(function () {
		window.location = "/" + $(this).val() + "/moya/boxman/admin"
	});

	$('#boxman_menu .boxman_template').draggable({
		revert: "invalid", // when not dropped, the item will revert back to its initial position
		containment: $("#boxman").length ? "#boxman" : "document", // stick boxman if possible
		helper: "clone",
		cursor: "move",
		connectToSortable: '.boxman_section_boxes' // Connect with sections
	});

	var $sectionBoxes = $('.boxman_section_boxes');
	$sectionBoxes.sortable({
		cancel : '.icons-wrapper',
		connectWith: '.boxman_section_boxes', // Connect with other sections
		receive : function(event, ui) {
			var id = ui.item.attr('id');
			if (id != undefined) {
				if(id.length > 0) {
					var $this = $(this);
					var $section = $(this).attr('id').replace('boxman_section_', '');
					var $id = ui.item.attr('id').replace('boxman_box_', '');
					// Handle movement between
					$.get(moya.boxman.urls.updatesection, {id : $id, section : $section}, function() {
						moya.boxman.sortBoxes($this.sortable("serialize"));
					});
				}
			}
		},
		beforeStop: function(event, ui) {
			// Already in the list
			var id = ui.item.attr('id');
			if (id == undefined || id == '') {
				var $this = $(this);
				var $section = $this.attr('id').replace('boxman_section_', '');
				var $item = ui.item;

				var $url = $item.find('.boxman_template_url').html();
				var $title = $item.find('.boxman_template_title').html();

				// $('.boxman_template').draggable("option", "disabled", true);
				$sectionBoxes.sortable("option", "disabled", true);
				$.post($url, {section : $section, division : moya.boxman.divisionId}, function (data) {

					var $data = data.box;
					var $title = $data.title;

					$item.attr('id', 'boxman_box_' + $data.id);
					var $section = $('<span></span>').attr('class', 'boxman_template_section').html($section);
					$item.append($section);
					var $icons = $('<span></span>').attr('class', 'boxman_template_icons')
						.append($data.icons);

					$item.find('.icons-wrapper').html($icons);
					// $('.boxman_template').draggable("option", "disabled", false);
					$sectionBoxes.sortable("option", "disabled", false);
					moya.boxman.sortBoxes($this.sortable("serialize"));

					$icons.find('.editIcon').trigger('click');

				}, 'json');
			}
		},
		update: function(event, ui) {
			moya.boxman.sortBoxes($(this).sortable("serialize"));
		}
	});

	$sectionBoxes.disableSelection();

	var $dialog = $("#dialog").dialog({
		modal : true,
		autoOpen : false,
		height : 420,
		width : 350,
		close : function() {
			// Beware of ghosts ;-D
			$(this).html('');
		}
	});

	var $deleteDialog = $("#dialog-delete").dialog({
		modal : true,
		autoOpen : false,
		height : 140,
		width : 400,
		close : function() {
			$(this).html('');
		}
	});

	$('.boxman_template_icons .editIcon').live('click', function () {
		$boxId = $(this).attr('id').replace('box_icon_edit_', '');
		$.get(moya.boxman.urls.edit, {id : $boxId}, function (data) {
			$dialog.html(data);
		});
		$dialog.dialog("option", "title", $(this).attr('title'));
		$dialog.dialog("open");
	});

	$('.boxman_template_icons .visibleIcon').live('click', function () {
		// Disable click
		$(this).hide();
		$(this).siblings('.disableIcon').show();

		$boxId = $(this).attr('id').replace('box_icon_visible_', '');
		$this = $(this);
		$.get(moya.boxman.urls.toggle, {id : $boxId}, function (data) {
			// Disable click
			if(data.visible == 1) {
				$this.attr('src', '/_moya/icons/Flag_Green.png');
				$('#boxman_box_' + $boxId + ' .boxman_template_title')
					.removeClass('boxman_hide');
			}
			else {
				$this.attr('src', '/_moya/icons/Flag_Black.png');
				$('#boxman_box_' + $boxId + ' .boxman_template_title')
					.addClass('boxman_hide');
			}
			// Enable click
			$this.siblings('.disableIcon').hide();
			$this.show();
		});
	});

	$('.boxman_template_icons .deleteIcon').live('click', function () {
		$boxId = $(this).attr('id').replace('box_icon_delete_', '');
		$.get(moya.boxman.urls.delete, {id : $boxId}, function (data) {
			$deleteDialog.html(data);
		});
		$deleteDialog.dialog("option", "title", $(this).attr('title'));
		$deleteDialog.dialog("open");
	});
};

moya.boxman.deleteBox = function($id)
{
	$box = $('#boxman_box_' + $id);
	$box.remove();
	moya.boxman.sortBoxes();
};

moya.boxman.sortBoxes = function($data)
{
	$.get(moya.boxman.urls.sort, $data);
};