moya = window.moya || {};

moya.gallery = {};

moya.gallery.crop = {
	width: 160,
	height: 160,
	init: function() {
		if($('#tab2').hasClass('ui-state-active')){
			console.log('crop.init');
			moya.gallery.crop.showCrop($(this).find('a'));
		}
		$('body').keydown(function(e) {
			if(e.keyCode == 13 && $('#tab2').hasClass('ui-tabs-selected')) {
				e.preventDefault();
				moya.gallery.crop.saveCrop(true);
			}
		});
	},
	showCrop: function(anchor) {
		console.log('showcrop');

		$(anchor).removeAttr('onclick');
		var w = 160;
		var h = 160;

		if (moya && moya.gallery && moya.gallery.crop) {
			if (moya.gallery.crop.width) {
				w = moya.gallery.crop.width;
			}
			if (moya.gallery.crop.height) {
				h = moya.gallery.crop.height;
			}
		}

		$('#crop img').Jcrop({
			aspectRatio: 1,
			onChange: moya.gallery.crop.showCropPreview,
			onSelect: moya.gallery.crop.showCropPreview,
			minSize: 160,
			boxWidth: 600,
			boxHeight: 600,
			setSelect: [ 0, 0, w, h ]
		});
	},
	showCropPreview: function(coords)
	{
		/*
		var normal = $('#crop img');
		var thumb = $('#preview img');
		var rx = thumb.width() / c.w;
		var ry = thumb.height() / c.h;
		var height = normal.height();
		var width = normal.width();

		$('#crop_h').val(c.h);
		$('#crop_w').val(c.w);
		$('#crop_x').val(c.x);
		$('#crop_y').val(c.y);

		thumb.css({
			width: Math.round(rx * width) + 'px',
			height: Math.round(ry * height) + 'px',
			marginLeft: '-' + Math.round(rx * c.x) + 'px',
			marginTop: '-' + Math.round(ry * c.y) + 'px'
		});
		*/

		var crop = $('#crop img'),
			rx = 100 / coords.w,
			ry = 100 / coords.h;

		$('#crop_h').val(coords.h);
		$('#crop_w').val(coords.w);
		$('#crop_x').val(coords.x);
		$('#crop_y').val(coords.y);

		$('#preview img').css({
			width: Math.round(rx * crop.width()) + 'px',
			height: Math.round(ry * crop.height()) + 'px',
			marginLeft: '-' + Math.round(rx * coords.x) + 'px',
			marginTop: '-' + Math.round(ry * coords.y) + 'px'
		});
	},
	saveCrop: function(submit) {
		var data = {
			id: $('#crop_id').val(),
			h: $('#crop_h').val(),
			w: $('#crop_w').val(),
			x: $('#crop_x').val(),
			y: $('#crop_y').val()
		};

		jQuery.ajax({
			url: '/_/moya/gallery/image/savecrop/',
			type: 'POST',
			data: data,
			success: function(r){
				var j = jQuery.parseJSON(r);
				var n = $('#notice');

				if (j.error) {
					n.html('<div class="message"><div class="error">' + j.error + '</div></div>');
				}
				else if (j.info) {
					if (submit == true) {
						$('#submit').trigger('click');
					}
					else {
						n.html('<div class="message"><div class="notice">' + j.info + '</div></div>');
						var i = $('.before img');
						var newSrc = i.attr('src').replace(/\?.*/, '');
						newSrc += '?' + new Date().getTime();
						i.attr('src', newSrc);
					}
				}
			}
		})
	}
};

moya.gallery.upload = {
	urls: {},
	lang: {},
	init: function(urls, lang) {
		moya.gallery.upload.urls = urls;
		moya.gallery.upload.lang = lang;

		$('.albumSelect.albumNew').each(function(){
			$(this).bind('change', function(){
				if ($(this).val() == '0') {
					$(this).parents('dd').next('dt').show().next('dd').show();
				}
				else {
					$(this).parents('dd').next('dt').hide().next('dd').hide();
				}
			}).trigger('change');
		});

		$('#ffile').uploadify({
			uploader: urls.uploader,
			script: urls.script,
			cancelImg: urls.cancelImg,
			auto: true,
			multi: true,
			folder: '/tmp',
			fileDesc: lang.galleryImageUploadFileDescription,
			fileExt: '*.jpg;*.jpeg;*.gif;*.png;*.JPG;*.JPEG;*.GIF;*.PNG',
			buttonText: lang.galleryImageBrowse,
			onError: function () {
				$('#notice').html('<div class="message"><div class="error">' + lang.galleryErrorOnSave + '</div></div>');
			},
			onComplete: function(e,q,f,r) {
				var n = $('#notice');
				switch(r) {
					case 'noAlbum':
						n.html('<div class="message"><div class="error">' + lang.galleryErrorNoAlbumName + '</div></div>');
						break;
					case 'noSave':
						n.html('<div class="message"><div class="error">' + lang.galleryErrorOnSave + '</div></div>');
						break;
					default:
						n.html('');
						$('#imageNew #submit').bind('click', function(e){
							e.preventDefault();
							window.location = urls.editMultiple + '/' + $('#imageNew .albumSelect').val();
						});
						try {
							var j = jQuery.parseJSON(r);
							$('#ffileList ul').append('<li><img src="' + j.tiny + '" /></li>');
						}
						catch (e) {}
						break;
				}
			},
			onAllComplete: function(){
				$('#imageNew #submit').removeAttr('disabled');
			},
			onSelect: function() {
				var data = {
					'albumId' : $('#imageNew #albumId').val(),
					'userId' : $('#imageNew #userId').val()
				};
				$('#ffile').uploadifySettings('scriptData', data);
			}
		});

		$('#ffileQueue').parent().append('<div id=\"ffileList\"><ul></ul></div>');
	}
};

moya.gallery.sort = {
	sortList: null,
	init: function(url) {
		moya.gallery.sort.sortList = $('#sortList');
		moya.gallery.sort.sortList.sortable({
			update: function(){
				var order = $(this).sortable('toArray').toString();
				$.ajax({
					type: 'POST',
					url: url,
					data: {order:order}
				});
			}
		});
		moya.gallery.sort.sortList.disableSelection();

		$('.ordType').change(function () {
			moya.gallery.sort.toggleSortList();
		});

		moya.gallery.sort.toggleSortList();
	},
	toggleSortList: function() {
		moya.gallery.sort.sortList.toggle($('#ordType-ord').is(':checked'));
	}
};

moya.gallery.gallery = {
	init: function() {
		var sortable = $('.sortable');
		if (sortable.length > 0) {
			sortable.sortable({
				connectWith: ['.sortable'],
				placeholder: 'ui-state-highlight'
			});
		}

		$('.quickOrder a').live('click', function () {
			moya.gallery.gallery.orderSelected($(this).attr('rel'));
		});

		// setting sortable uls to the max height
		var ulAvailable = $('#galleryAlbumsAvailable ul');
		var ulSelected = $('#galleryAlbumsSelected ul');
		var a = ulAvailable.height();
		var s = ulSelected.height();
		var max = s;
		if (a > s) {
			max = a;
		}
		var style = 'height:'+max+'px';
		ulAvailable.attr('style', style);
		ulSelected.attr('style', style);
	},
	orderSelected: function(type) {
		var mylist = $('#galleryAlbumsSelected ul');
		var listitems = mylist.children('li').get();
		if(type == 'insertedat') {
			listitems.sort(function(b, a) {
				return $(a).attr('insertedat').localeCompare($(b).attr('insertedat'));
			});
		}
		else {
			listitems.sort(function(a, b) {
				return $(a).text().toUpperCase().localeCompare($(b).text().toUpperCase());
			});
		}
		$.each(listitems, function(idx, itm) { mylist.append(itm); });
	}
};
