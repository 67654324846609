moya = window.moya || {};

moya.mailinglist = {};

moya.mailinglist.item = {
	maxAreas: 1,
	urlTemplatePreview: null,
	urlTemplateImage: null,
	init: function (maxAreas, urlTemplateImage, urlTemplatePreview) {
		moya.mailinglist.item.maxAreas = maxAreas;
		moya.mailinglist.item.urlTemplateImage = urlTemplateImage;
		moya.mailinglist.item.urlTemplatePreview = urlTemplatePreview;
		moya.mailinglist.item.switchTemplates($('#item_templateId').val());
	},
	switchTemplates: function (q) {
		if ("" != q) {
			$.getJSON(moya.mailinglist.item.urlTemplateImage, {templateId: q},
				function (json) {
					if (json.success) {
						$('#template_image img').attr({
							src: json.image
						});

						for (i = 1; i <= moya.mailinglist.item.maxAreas; i++) {
							if (i <= json.areaCount) {
								$('#item_area_entry' + i).show();
							}
							else {
								$('#item_area_entry' + i).hide();
							}
						}
					}
				}
			)
		}
	},
	newPreview: function () {
		for (i = 1; i <= moya.mailinglist.item.maxAreas; i++) {
			var content = tinyMCE.editors['item_area' + i].getContent();
			$('#item_area' + i).val(content);
		}

		var html = $.ajax({
			type: "POST",
			url: moya.mailinglist.item.urlTemplatePreview,
			async: false,
			dataType: "html",
			data: $("#itemForm").serialize()
		}).responseText;
	},
	closePreview: function () {
		$('#previewOverlay').hide();
		$('#preview').hide();
	},
	switchList: function (listId, url) {
		if (-1 != listId) {
			$.getJSON(url, {list_id: listId}, function (json) {
				if (json.success) {
					$("#listinfo dd").each(function (i) {
						$(this).html(json[$(this).attr('id')]);
					});
				}
			});
			$("#listinfo dd").animate({
				color: "#993333"
			}, 2000).animate({
				color: "#000"
			});
		}
	}
};