moya = window.moya || {};

moya.employees = {
	$toolbars: [],
	$content: [],
	init: function() {
		moya.employees.$toolbars = $('#employees .staff .toolbar');
		moya.employees.$content = $('#employees .staff .content');
		moya.employees.$toolbars.click(function(e) {
			if ($(e.target).is('a')) {
				if ($(e.target).attr('href').length <= 1) {
					if (e.isDefaultPrevented() === false) {
						moya.employees.toggleToolbar($(e.target).parents('.toolbar'));
					}
					e.preventDefault();
				}
			}
			else {
				if (e.isDefaultPrevented() === false) {
					moya.employees.toggleToolbar($(this));
				}
				e.preventDefault();
			}
		});
	},
	toggleToolbar: function($toolbar) {
		moya.employees.$content.addClass('hidden');
		moya.employees.$toolbars.not($toolbar).removeClass('open');
		if (!$toolbar.hasClass('open')) {
			$toolbar.addClass('open');
			var c = $toolbar.next('.content');
			c.find('img.bttrlazyloading').each(function() {
				var $img = $(this);
				if ($img.attr('src') == undefined) {
					$img.trigger('bttrlazyloading.load');
				}
			});
			c.removeClass('hidden');
		}
		else {
			$toolbar.removeClass('open');
		}
	}
};

$(function() {
	moya.employees.init();
});